import * as React from 'react'
import './growthZone.css'

export const GrowthZoneIcon = (props: any) =>
<svg version="1.1" id="layer" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 652 652" className='st' xmlSpace="preserve">
<g>
	<path className="st0" d="M404.4,273h58.3c-0.6,3.4-1.2,6.8-2,10.2c-17.8,18.1-35.9,35.9-53.6,54.2H450l-2.1,11c-20,0-40.1,0.1-60.1,0
		c0.6-3.1,1.1-6.2,1.7-9.2c0.1-0.7,0.7-1.1,1.1-1.5c14.7-15,29.5-29.9,44.2-44.8c2.7-2.8,5.6-5.4,8.1-8.3h-40.5
		C403.1,280.6,403.8,276.8,404.4,273z"/>
	<path className="st0" d="M480.2,292c6.1-0.9,12.5-0.7,18.3,1.6c4.4,1.8,8.1,5.2,9.9,9.7c2.1,5,2.1,10.8,1.1,16.2
		c-1.3,7.4-4.4,14.7-9.9,20c-5.1,5-12.1,8-19.2,8.5c-5.5,0.3-11.1,0.2-16.2-2.2c-4.8-2.1-8.6-6.3-10.3-11.4
		c-1.9-5.7-1.4-12.1,0.2-17.8c1.9-7,5.7-13.5,11.5-18.1C469.9,295.3,474.9,293,480.2,292z M482.2,302.2c-3.1,0.5-6.1,1.8-8.5,3.8
		c-4.5,3.8-7,9.4-7.9,15.1c-0.6,3.8-0.7,7.9,1.2,11.4c1.4,2.6,4,4.3,6.8,4.9c5.2,1.1,11,0,15.2-3.3c3.8-3,6-7.4,7.3-12
		c0.9-3.7,1.5-7.5,0.9-11.3c-0.4-2.7-1.9-5.3-4.2-6.8C489.9,301.9,485.9,301.7,482.2,302.2L482.2,302.2z M531.6,294.9
		c4.3-2.1,9-3.4,13.7-3.4c4.3,0,8.8,0.4,12.5,2.8c2.6,1.5,4.3,4.1,5.2,6.9c1.5,4.5,1.2,9.5,0.4,14.2c-2,10.5-4.1,21-6,31.5
		c-4.4,0.1-8.6,0-13,0c1.9-10,3.8-19.9,5.8-29.9c0.6-3.6,1.4-7.4,0.7-11c-0.3-1.8-1.7-3.3-3.5-3.8c-6.2-1.9-12.7,0.7-17.5,4.7
		c-0.9,3.4-1.4,7-2.1,10.5c-2,9.8-3.7,19.6-5.7,29.3c-4.1,0.3-8.3,0-12.5,0.2c3.4-18.1,6.9-36.2,10.5-54.3c3.8,0.1,7.7,0,11.6,0
		C531.7,293.5,531.7,294.3,531.6,294.9L531.6,294.9z M574.4,304c5-6.6,12.6-11.1,20.8-12.1c6.2-0.8,12.9,0.2,17.9,4.1
		c3.7,2.9,5.9,7.4,6.6,12.1c0.9,5.6,0.1,11.4-1.5,16.8c-13,0.1-25.9,0-38.9,0.1c-0.3,2.8,0.3,5.9,2.1,8.1c2.9,3.6,7.8,5,12.2,4.6
		c6.4-0.5,12.7-2,19-3.5c-0.5,3.6-1.4,7.1-2,10.6c-8,2.1-16.4,3.8-24.8,3.2c-6.1-0.5-12.4-3.3-15.7-8.6c-3-4.8-3.5-10.6-3-16.2
		C567.8,316.3,570.1,309.5,574.4,304L574.4,304z M581.3,315h26.6c0.3-3.3-0.3-7-2.6-9.6c-2.6-2.9-6.8-3.7-10.4-3
		C588.5,303.7,583.2,308.8,581.3,315L581.3,315z M338.7,360.1c1.5-0.7,3.3,0.7,3.2,2.4c-0.1,1.7-2.3,2.7-3.7,1.6
		C336.7,363.1,337,360.6,338.7,360.1L338.7,360.1z M377.1,360.1c1.5-0.8,3.4,0.7,3.3,2.3c-0.1,1.8-2.4,2.7-3.8,1.6
		C375.2,363.1,375.5,360.7,377.1,360.1z M365.9,361.7h3.7v4.9c1.2,0,2.5,0,3.7,0.1v3.3c-1.2,0.1-2.4,0.1-3.7,0.1v9.8
		c0.1,0.9-0.1,2.2,0.9,2.7c0.9,0.3,1.8-0.4,2.6-0.9c0.3,1,1,2.1,0.9,3.1c-1.5,1.4-3.9,1.6-5.9,0.9c-1.5-0.7-2.2-2.4-2.2-4V370H363
		v-3.3h2.9C365.9,364.9,365.9,363.3,365.9,361.7z M244.5,369.4c2.7-3.2,7.4-4.1,11.3-2.7c2.3,0.8,3.7,3.2,3.7,5.5v13h-3.7
		c0-0.3,0-1,0.1-1.4c-2.8,2.2-7.2,2.6-10,0.2c-2.9-2.3-2.8-7.4,0.1-9.6c2.9-2.2,7-1.7,9.9,0.3c0-1.6,0.3-3.6-1.4-4.6
		c-2.7-1.6-6.2-0.4-8.1,2C245.6,371.2,245.1,370.3,244.5,369.4z M248.9,376.6c-1.8,1.1-1.9,4.1-0.1,5.4c2.1,1.5,5.3,0.9,7-0.9
		c0.2-1.1,0.1-2.2,0.1-3.3C254,376,251.1,375.3,248.9,376.6z M265.4,367.4c3.7-2.2,8.9-1.5,11.9,1.6c-0.6,0.9-1.2,1.8-1.8,2.7
		c-1.9-2.1-5.1-3.2-7.8-2c-1.2,0.4-1.6,2.3-0.4,3c3,1.7,7.2,1.1,9.4,4.1c1.5,2.1,1.1,5.4-0.9,7c-2,1.7-4.8,2-7.2,1.7
		c-2.4-0.3-4.6-1.4-6.2-3.1c0.6-0.9,1.3-1.8,1.9-2.7c2.1,2.1,5.5,3.5,8.5,2.3c1.3-0.5,1.9-2.6,0.6-3.4c-2.7-1.8-6.3-1.3-8.8-3.5
		C262.2,373.1,262.8,368.9,265.4,367.4L265.4,367.4z M284.7,366.4c3.4-0.9,7.4,0,9.8,2.6c-0.6,0.9-1.2,1.8-1.7,2.7
		c-1.9-2.1-5-3.2-7.8-2c-1.2,0.4-1.7,2.4-0.4,3.1c2.9,1.6,6.9,1.1,9.2,3.8c1.8,2.3,1.2,6-1.2,7.6c-4,2.5-9.8,1.7-13-1.7
		c0.7-0.9,1.3-1.8,1.9-2.7c1.7,1.7,4,2.9,6.4,2.7c1.2-0.1,2.7-0.4,3.2-1.6c0.4-0.9-0.1-2.1-1-2.6c-2.6-1.3-5.7-1.1-8-2.9
		c-1.9-1.4-2.2-4.2-1.1-6.2C281.6,367.7,283,366.8,284.7,366.4z M304,366.4c3.1-0.8,6.6-0.2,8.9,2.1c3.9,3.8,3.9,11-0.1,14.9
		c-3.8,3.6-10.6,3.1-13.6-1.3c-2.2-3.1-2.5-7.4-0.9-10.8C299.4,368.9,301.5,367.1,304,366.4L304,366.4z M305.2,369.6
		c-4.4,1.2-5.2,7.3-2.8,10.6c1.7,2.6,5.8,2.8,7.9,0.5c2.1-2.3,2.3-6.1,0.8-8.7C309.9,370,307.4,369,305.2,369.6L305.2,369.6z
		 M323,367.3c3.8-2.1,9.4-1.5,11.8,2.5c-0.9,0.7-1.5,1.5-2.4,2.1c-0.8-0.4-1.2-1.4-2-1.7c-2.6-1.5-6.3-0.3-7.5,2.4
		c-1.5,3.2-0.8,7.9,2.7,9.3c2.4,1.1,5.3,0.1,6.8-2.1c0.9,0.8,1.6,1.5,2.5,2.3c-2.8,4.4-9.5,4.7-13.3,1.4
		C316.8,379.3,317.4,370.4,323,367.3L323,367.3z M345.7,369.4c3.1-3.5,8.8-4.4,12.7-1.9c1.5,1.1,2.3,2.9,2.2,4.7v12.9H357
		c0-0.3,0-0.8-0.1-1c-1,0.2-1.9,1-2.9,1.2c-2.8,0.8-6.2,0.2-8-2.3c-2-2.7-1.5-7,1.4-8.8c2.9-2,6.8-1.4,9.6,0.4
		c0-1.5,0.3-3.5-1.3-4.5c-2.7-1.7-6.3-0.3-8.3,2C346.9,371.2,346.3,370.3,345.7,369.4z M351,376.2c-2.1,0.6-3,3.3-1.7,5
		c1.4,1.8,4,1.9,5.9,1c0.9-0.4,2.1-1,1.9-2.2c-0.1-0.9,0.3-2.2-0.6-2.8C355,376,352.9,375.6,351,376.2L351,376.2z M390.9,366.2
		c2.7-0.4,5.6,0.2,7.7,2c4.4,3.8,4.4,11.4,0.2,15.2c-3.8,3.4-10.6,2.8-13.5-1.5c-2-2.7-2.3-6.5-1.3-9.7
		C384.9,369.2,387.8,366.7,390.9,366.2L390.9,366.2z M391.1,369.7c-4.3,1.5-5,7.6-2.3,10.9c1.9,2.4,6,2.4,7.9-0.1
		c1.9-2.4,2-6,0.4-8.6C395.9,369.9,393.3,368.9,391.1,369.7L391.1,369.7z M409.2,368.3c1.5-1.1,3.3-2.1,5.4-2.2
		c2.1-0.2,4.4,0.3,5.6,2.2c1.2,1.8,1,3.9,1,6c-0.1,3.7,0.1,7.3-0.1,11h-3.6c-0.1-4.2,0.1-8.4-0.1-12.6c0-1.4-0.8-2.8-2.1-3.1
		c-2.3-0.6-4.6,0.5-6.2,2.2v13.3h-3.5c-0.3-6.2,0-12.5-0.1-18.7h3.7C409.2,367.1,409.2,367.7,409.2,368.3L409.2,368.3z M337.7,366.5
		h3.7c-0.1,6.2,0,12.4,0,18.6c-1.2,0-2.4,0-3.6,0.1C337.5,379,337.7,372.8,337.7,366.5L337.7,366.5z M376.2,366.5h3.7v18.6h-3.6
		C376.1,378.9,376.2,372.8,376.2,366.5L376.2,366.5z"/>
	<path className="st1" d="M57.3,263.5c12.7-5.6,26.9-7.1,40.5-4.2c7.6,1.5,15.2,4.8,20.4,10.9c4.7,5.4,6.5,12.8,6.1,19.8H95.4
		c-0.4-2.8-1.8-5.5-3.9-7.4c-3.8-3.3-9.1-4.7-14-4c-6.2,0.6-11.5,4.3-15.6,8.7c-2.7,3.1-4.7,6.8-6,10.7c-1.9,5.5-3.3,11.3-2.8,17.2
		c0.8,5,3.3,10,7.6,13c3.8,2.7,8.9,3.5,13.5,2.5c7.4-1.6,13.3-6.8,17.5-13c-5-0.1-10-0.1-15-0.1c1.5-5.6,3-11.2,4.4-16.9
		c14.4-0.1,28.7-0.1,43.1,0c-3.4,15.7-6.8,31.5-10.4,47.2c-6-0.1-12,0-17.9,0c0.6-3.7,1.3-7.4,1.9-11c-7.6,6.1-16.5,10.5-26,12.7
		c-4,0.9-8.1,1.3-12.2,1.5c-4.7,0.2-9.4-0.6-13.9-2.1c-7.2-2.1-13.9-6.4-18.5-12.4c-5.1-6.6-7.6-15.1-7.2-23.4
		c0.4-9.3,3.9-18.3,9.2-25.9C35.7,276.5,45.9,268.4,57.3,263.5z M347,274.2c7-0.9,13.9-2.3,20.7-4.4c-1.5,8.1-3.2,16.3-4.7,24.3
		c5.2-1.6,10.7-2.9,16.2-2c4.4,0.6,8.8,2.9,11,6.9c2.9,5,2.7,11,1.7,16.6c-2.1,11-4.1,21.9-6.2,32.8c-6.9-0.1-13.8,0-20.7,0
		c2-10,3.8-20.1,5.8-30.1c0.4-2.3,0.8-4.8,0.3-7.1c-0.2-1.2-1-2.3-2.2-2.6c-2.8-0.8-5.7,0.1-8.4,0.9c-2.5,13-5,26-7.5,39h-20.4
		C337.4,323.7,342.2,299,347,274.2z M312.6,282.4c7.2-2.1,14.4-3.9,21.6-5.9c-1,5.6-2.1,11.1-3.2,16.7H341c-1,5.4-2.1,10.8-3.3,16.1
		c-3.3-0.1-6.7,0.1-9.9-0.1c-1.2,5.3-2.1,10.7-3.2,16.1c-0.3,2.1-0.9,4-0.9,6.1c3.3,0.3,6.6,0,9.8,0.2c-1.2,5.7-2.2,11.5-3.3,17.3
		c-6.2-0.2-12.4,0.5-18.5-0.9c-3.4-0.9-6.7-3-8-6.3c-1.8-4.3-0.9-9.1,0-13.4c1.2-6.3,2.5-12.6,3.6-18.9h-6.8l-20.5,39.3
		c-6.4,0.1-12.7,0-19.2,0c-0.5-7.1-0.9-14.2-1.3-21.2c-3.3,7-6.3,14.1-9.5,21.2c-6.3,0.1-12.7,0-19.1,0c-2.7-18.5-5-37-7.6-55.3
		h21.8c0.5,7.5,1.1,15.1,1.6,22.7c3.5-6.8,6.9-13.8,10.4-20.7h16.5c0.8,6.8,1.5,13.7,2.3,20.4c0.8-1,1.1-2.2,1.7-3.3
		c3-6.4,5.9-12.8,9-19.2c8,0.1,15.8-0.1,23.8,0.1C311.3,289.6,312,286,312.6,282.4L312.6,282.4z M171.2,303.4
		c5.8-6.6,14.3-10.8,23-11.5c6.5-0.4,13.3,0.3,19.1,3.6c4.6,2.6,8.1,7.1,9.3,12.3c1.5,6.2,0.5,12.9-1.5,18.9
		c-2.6,7.4-7.7,13.9-14.5,17.8c-6.8,4-14.8,5.5-22.6,5c-6.3-0.5-12.7-3.3-16.9-8.1c-3.3-3.8-4.9-8.7-5-13.7
		c-0.2-6.1,1.5-12.1,4.3-17.5c-2.9-0.3-6-0.5-8.9,0.1c-2.9,0.5-5.6,1.6-8.3,2.9c-2.4,11.8-4.5,23.6-6.8,35.3H122
		c3.6-18.5,7.2-36.9,10.8-55.3c5.9,0,11.9,0.1,17.8,0c0.3,1.3,0.3,2.7,0.3,3.9c3.5-2,7.2-3.8,11.1-4.4c3-0.5,6.1-0.1,9.1,0.3
		c-0.9,4.6-1.9,9.2-2.7,13.8C169.4,305.6,170.2,304.5,171.2,303.4z M193.7,309.1c-6.2,1.5-10.6,7.4-11,13.6c-0.3,3,0.5,6.3,3,8.2
		c2.6,2,6.2,1.7,9,0.3c4.6-2.2,7.4-7.3,8.1-12.2c0.4-2.8,0-6-2.1-8.1C199,309.1,196.1,308.6,193.7,309.1L193.7,309.1z M475.4,361.6
		c1.6-2.5,5.3-2.8,7.8-1.4c-0.3,0.9-0.7,1.9-0.9,2.9c-1-0.5-2.2-0.9-3.3-0.2c-1.1,0.9-0.9,2.3-1,3.6h6.6v-4.9h3.6v4.9h3.7v3.3
		c-1.2,0.1-2.4,0.1-3.6,0.1v10.2c0,0.8,0,1.9,0.9,2.2s1.9-0.3,2.6-1c0.3,0.9,0.8,2,1.2,2.9c-1.5,1.6-4.1,1.9-6.1,1
		c-1.5-0.7-2.2-2.4-2.1-3.9v-11.5h-6.6c-0.1,5.1,0,10.3,0,15.3h-3.6c-0.1-5.1,0-10.3,0-15.4h-2.9v-3.3h2.9
		C474.3,364.8,474.4,363,475.4,361.6z M192,361.8c1.2-0.1,2.5-0.1,3.7,0c-0.1,1.6,0,3.2,0,4.8h3.6v3.3h-3.5c-0.1,3.4,0,6.8,0,10.2
		c0,0.9,0,2,0.9,2.2s1.8-0.3,2.4-1c0.4,0.9,0.9,2,1.3,3c-1.6,1.5-4.2,1.9-6.2,0.9c-1.4-0.8-2.1-2.4-2.1-3.8V370h-3v-3.3h2.9
		C192.1,364.9,192.1,363.3,192,361.8L192,361.8z M114.5,367.2c3.7-2.1,8.8-1.4,11.8,1.7c-0.6,0.9-1.2,1.8-1.8,2.7
		c-1.7-2.2-4.8-3.1-7.4-2.1c-0.9,0.3-1.7,1.3-1.5,2.3c0.3,0.9,1.2,1.2,2,1.5c2.4,0.9,5.1,0.9,7.2,2.6c2.4,1.9,2.3,5.9,0.1,7.9
		c-1.9,1.7-4.6,2.1-7.1,1.8c-2.4-0.2-4.8-1.3-6.4-3.1c0.7-0.9,1.3-1.8,2-2.7c1.9,2.2,5,3.3,7.8,2.6c1-0.3,1.9-1.1,1.9-2.2
		c0.1-1.1-1-1.7-1.9-2.1c-2.7-1-5.8-1-8-3.1C111.1,372.7,111.9,368.8,114.5,367.2L114.5,367.2z M133.8,368.3
		c1.7-1.4,3.8-2.4,6.2-2.1c1.7,0.1,3.3,1.2,4.1,2.7c2.2-2.5,6.2-3.7,9.2-1.8c1.5,1.1,2.1,3,2,4.8v13.3c-1.2,0-2.5,0.1-3.7-0.1
		c0.1-4,0-8,0.1-12.1c0.1-1.3-0.3-3-1.7-3.4c-2-0.6-4.1,0.6-5.3,2.1c-0.2,4.4-0.1,8.9-0.1,13.4c-1.2,0.1-2.5,0.1-3.7,0.1v-12.1
		c0-1.3-0.2-2.9-1.6-3.4c-2.1-0.8-4.2,0.7-5.5,2.2c0,4.4,0.1,8.9-0.1,13.3c-1.2,0-2.4-0.1-3.6,0v-18.6h3.7
		C133.9,367.1,133.9,367.7,133.8,368.3L133.8,368.3z M159.7,369.4c3-3.4,8.6-4.4,12.5-2.1c1.8,1.1,2.6,3.3,2.5,5.3
		c0,4.2,0.1,8.4-0.1,12.6h-3.5c0-0.3,0-1-0.1-1.3c-2.8,2.1-7.1,2.6-9.9,0.3c-3-2.3-2.9-7.5,0.2-9.8c2.8-2.1,7-1.7,9.8,0.3
		c0-1.5,0.3-3.6-1.3-4.6c-2.7-1.7-6.3-0.4-8.3,1.9C160.9,371.2,160.3,370.3,159.7,369.4L159.7,369.4z M164,376.7
		c-1.7,1.2-1.7,4.2,0.1,5.3c2.2,1.4,5.4,0.8,7-1.1c-0.1-1,0.1-2.1-0.2-3.2C169.3,375.8,166.1,375.3,164,376.7z M206.3,367.1
		c3.5-1.8,8.3-1.2,10.9,1.9c2,2.2,2.5,5.3,2.4,8.2c-4.8,0.1-9.6,0.1-14.4,0c0.3,2,1.4,3.8,3.2,4.7c2.7,1.3,6.2,0.4,8.2-1.9
		c0.7,0.9,1.3,1.6,1.9,2.5c-3.7,3.9-10.6,4.3-14.5,0.4C199.6,378.5,200.7,370,206.3,367.1z M206.7,370.8c-0.9,0.9-1.4,2.2-1.6,3.5
		c3.6,0,7.3,0.1,10.9-0.1c-0.3-1.2-0.7-2.4-1.5-3.3C212.6,368.8,208.7,368.7,206.7,370.8z M226.7,368.5c1.6-1.5,3.7-2.5,6-2.4v3.8
		c-2.2-0.6-4.5,0.5-5.8,2.2c-0.2,4.3-0.1,8.6,0,13c-1.2,0.1-2.5,0.1-3.7,0.1c0-6.2,0.1-12.4,0-18.6c1.2-0.1,2.5-0.1,3.7-0.1
		C226.8,367.2,226.7,367.9,226.7,368.5z M436.9,367.1c3.7-2,8.6-1.3,11.5,1.8c-0.3,1-1.2,1.9-1.8,2.7c-1.6-2.1-4.6-3-7.1-2.2
		c-1,0.3-2,1.3-1.7,2.4c0.3,1,1.5,1.4,2.5,1.6c2.5,0.8,5.4,0.9,7.3,2.9c2.2,2.5,1.2,6.8-1.8,8.2c-3.9,1.9-9.2,1.1-12.2-2.2
		c0.7-0.9,1.3-1.8,1.9-2.7c2.1,2.3,5.6,3.6,8.5,2.3c1.5-0.5,1.8-2.8,0.3-3.5c-2.7-1.6-6.4-1.2-8.8-3.5
		C433.3,372.8,434.1,368.6,436.9,367.1L436.9,367.1z M458.2,366.4c2.7-0.7,5.9-0.3,8.2,1.5c4.8,3.8,4.8,12.1,0,15.9
		c-3,2.3-7.4,2.4-10.6,0.4c-3.9-2.5-5.1-7.8-3.7-12C452.9,369.5,455.3,367.1,458.2,366.4z M459.3,369.6c-4.4,1.2-5.2,7.3-2.8,10.6
		c1.7,2.6,5.8,2.8,7.9,0.6c2.1-2.4,2.3-6.3,0.6-9C463.8,369.9,461.4,369,459.3,369.6L459.3,369.6z M521.6,369.4
		c1.6-1.7,3.8-3,6.2-3.2c2.7-0.3,5.7,0.1,7.5,2.3c1.3,1.5,1.4,3.6,1.3,5.4v11.2c-1.2,0.1-2.5,0.1-3.7,0v-1.3
		c-2.7,2.1-6.9,2.6-9.8,0.3c-3.3-2.3-3.2-7.8,0.2-10c2.9-2,6.8-1.5,9.7,0.4c-0.1-1.5,0.2-3.6-1.4-4.6c-2.7-1.7-6.3-0.3-8.2,2
		C522.8,371.2,522.2,370.3,521.6,369.4L521.6,369.4z M525.7,376.7c-1.7,1.3-1.6,4.1,0.1,5.3c2.2,1.5,5.4,0.8,7.1-1.1
		c-0.1-1.1,0.1-2.2-0.2-3.3C531,375.8,527.9,375.3,525.7,376.7L525.7,376.7z M544.8,368.5c1.6-1.5,3.7-2.5,6-2.4v3.8
		c-2.2-0.6-4.5,0.6-5.9,2.3v12.9c-1.2,0-2.5,0-3.7-0.1c0.1-6.2,0-12.4,0-18.6h3.7C544.8,367.2,544.8,367.9,544.8,368.5z
		 M557.4,367.1c3.4-1.7,8-1.2,10.6,1.8c2.1,2.2,2.7,5.4,2.5,8.4h-14.4c0.3,2,1.5,3.9,3.3,4.7c2.7,1.3,6.2,0.3,8.2-1.9
		c0.7,0.9,1.3,1.6,1.9,2.5c-3.8,4.1-11.4,4.3-15.1-0.2C550.7,377.8,551.9,369.8,557.4,367.1L557.4,367.1z M558,370.6
		c-1.1,0.9-1.6,2.3-1.9,3.8h10.8c-0.3-1.2-0.6-2.4-1.5-3.3C563.6,368.9,560,368.7,558,370.6L558,370.6z M179.3,366.5
		c1.2-0.1,2.5-0.1,3.7-0.1v2.1c1.6-1.5,3.7-2.6,5.9-2.5v3.8c-2.1-0.6-4.3,0.4-5.6,2c-0.4,0.4-0.3,1-0.3,1.5c0.1,3.8,0,7.8,0.1,11.6
		c-1.2,0.1-2.5,0.1-3.7,0.1C179.4,378.9,179.4,372.8,179.3,366.5L179.3,366.5z M492.3,366.5c1.3,0,2.6-0.1,3.8,0.1
		c1.3,4.4,2.6,8.7,4,13c1.4-4.4,2.8-8.7,4.3-13.1c0.9,0,2-0.1,2.9,0c1.5,4.2,2.8,8.6,4.3,12.7c0.3-0.3,0.4-0.7,0.6-1
		c1.2-3.8,2.3-7.8,3.6-11.6c1.1-0.4,2.6-0.1,3.8-0.2c-2,6.2-3.9,12.4-5.9,18.6h-3.4c-1.5-4.4-2.8-8.7-4.3-13.1
		c-1.6,4.3-2.9,8.7-4.4,13.1h-3.4C496.2,378.9,494.3,372.8,492.3,366.5L492.3,366.5z"/>
</g>
</svg>
